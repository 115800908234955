import React from "react";
import cx from "classnames";
import home from "../images/undraw_Chilling_edited.svg";
import freeConsult from "../images/noun_consult_3810574.svg";
import certified from "../images/noun_certified_2464533.svg";
import universal from "../images/noun_universe_3984172.svg";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

const StyledButton = ({ className, children, ...props }) => {
  className = cx(
    "py-2 px-4 bg-blue-700 hover:bg-blue-800 text-base text-white font-bold uppercase rounded shadow-md hover:-translate-1",
    className
  );
  return (
    <button className={className} {...props}>
      {children}
    </button>
  );
};

const Service = ({ title, url, children }) => {
  return (
    <div className="w-full sm:w-1/2 md:w-1/3 p-2">
      <Link
        to={url}
        className="text-2xl text-blue-700 hover:text-blue-800 hover:underline"
      >
        {title}
      </Link>
      <p>{children}</p>
    </div>
  );
};

function Index({ data }) {
  const products = data.products.edges;
  const services = data.services.edges;

  return (
    <Layout headerClass="relative bg-white">
      <SEO title="Home" />
      <div
        className="min-h-screen pt-24 sm:pt-32 md:pt-64 -mt-12 sm:-mt-16 md:-mt-24 lg:-mt-28 mb-20 bg-size-5/6 md:bg-size-4/5 lg:bg-size-2/3 bg-right-top bg-no-repeat flex flex-col items-center"
        style={{ backgroundImage: `url(${home})` }}
      >
        {/* SLOGAN */}
        <div className="w-4/5 md:w-3/4 lg:w-7/12 mt-20 font-serif font-light self-start">
          <h1 className="text-3xl md:text-5xl text-blue-700 leading-tight">
            BayernTek
          </h1>
          <p className="text-base">
            High quality & reliable connected devices for home and industry
          </p>
        </div>

        {/* CONTACTS */}
        <div className="container w-3/4 sm:w-7/12 lg:w-5/12 xl:1/4 mt-10 self-start">
          <div
            className="bg-white rounded flex flex-col sm:flex-row items-start sm:items-center text-sm p-4"
            style={{
              boxShadow:
                "0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)",
            }}
          >
            <div className="flex-1 text-gray-800">
              <p>
                <strong>Email: </strong>
                info@bayerntek.de
              </p>
            </div>
            <div className="flex-initial mt-6 sm:mt-0">
              <Link to="/contact">
                <StyledButton>Contact</StyledButton>
              </Link>
            </div>
          </div>
        </div>

        {/* PRODUCTS */}
        <div className="flex flex-col mt-10 md:mt-16">
          <p className="mb-2 text-4xl text-gray-700 self-center">
            Our Products
          </p>

          <div className="flex flex-wrap">
            {products.map(({ node }) => (
              <Service
                title={node.frontmatter.title}
                url={node.frontmatter.path}
                key={node.frontmatter.path}
              >
                {node.excerpt}
              </Service>
            ))}
          </div>

          <div className="self-center mt-8">
            <Link to="/products">
              <StyledButton>View all products</StyledButton>
            </Link>
          </div>
        </div>

        {/* FEATURES */}
        <div className="flex flex-col mt-10 md:mt-16">
          <p className="mb-2 text-4xl text-gray-800 self-center">
            Our Features
          </p>

          <div className="flex flex-wrap justify-center items-stretch -mx-2">
            <div className="w-full md:w-1/2 lg:w-1/3 mt-2">
              <div className="h-full m-2 p-4 border-2 border-gray-300 flex flex-col items-center text-center">
                <div className="w-16 h-16 mb-4">
                  <img alt="branding" src={freeConsult} />
                </div>
                <p className="text-2xl w-full">Free Consultation</p>
                <p>New clients recieve an obligation free consultation.</p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 mt-2">
              <div className="h-full m-2 p-4 border-2 border-gray-300 flex flex-col items-center text-center">
                <div className="w-16 h-16 mb-4">
                  <img alt="certified" src={certified} />
                </div>
                <p className="text-2xl w-full">Matter Certified Devices</p>
                <p>All devices are industry standard Matter certified.</p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 mt-2">
              <div className="h-full m-2 p-4 border-2 border-gray-300 flex flex-col items-center text-center">
                <div className="w-16 h-16 mb-4">
                  <img alt="universal" src={universal} />
                </div>
                <p className="text-2xl w-full">Universal platform</p>
                <p>
                  All devices are supported by Android, Apple iOS and Amazon
                  Alexa.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* SERVICES */}
        <div className="flex flex-col mt-10 md:mt-16">
          <p className="mb-2 text-4xl text-gray-700 self-center">
            Our Services
          </p>

          <div className="flex flex-wrap">
            {services.map(({ node }) => (
              <Service
                title={node.frontmatter.title}
                url={node.frontmatter.path}
                key={node.frontmatter.path}
              >
                {node.excerpt}
              </Service>
            ))}
          </div>

          <div className="self-center mt-8">
            <Link to="/services">
              <StyledButton>View all services</StyledButton>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    services: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/services/" }
        frontmatter: { featured: { eq: true } }
      }
      sort: { fields: [frontmatter___weight], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
            path
            featured
          }
          excerpt
        }
      }
    }
    products: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/products/" }
        frontmatter: { featured: { eq: true } }
      }
      sort: { fields: [frontmatter___weight], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
            path
            featured
          }
          excerpt
        }
      }
    }
  }
`;

export default Index;
